// gatsby
import { Link } from 'gatsby'
// react
import * as React from 'react'
import { useEffect } from 'react'
// react bootstrap components
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// ui 
import 'bootstrap/dist/css/bootstrap.css'
import LogoUpload from 'images/uploadCycleData.png'
import LogoPredict from 'images/predict.png'
import LogoReport from 'images/archive.png'
// internal components
import Layout from 'components/layout'
import styles from '../styles.css'
const IndexPage = (props) => {
  console.log(props)
  useEffect(() => {
    props.fetch()
  }, [])
  return (
    <Container>
      <CardGroup className='justify-content-center'>
        <Row>
          <Col>
            <Card style={{ width: '18rem', border: 'none' }}>
              <Link to='/eigen/history'>
                <Card.Img variant='top' src={LogoUpload} className='img-fluid card-animation' />
              </Link>
              <Card.Body>
                <h1 className='lead' style={{ fontWeight: '100' }}>Historical Data</h1>
                <p>Used for model refinements to increase predictive power</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem', border: 'none' }}>
              <Link to='/eigen/predictions'>
                <Card.Img variant='top' src={LogoPredict} className='img-fluid card-animation' />
              </Link>
              <Card.Body>
                <h1 className='lead' style={{ fontWeight: '100' }}>Eigenvalue Projections</h1>
                <p>Submit depletions to generate eigenvalue projections</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem', border: 'none' }}>
              <Link to='/eigen/reports'>
                <Card.Img variant='top' src={LogoReport} className='img-fluid card-animation' />
              </Link>
              <Card.Body>
                <h1 className='lead' style={{ fontWeight: '100' }}>
                  Report Archive
                </h1>
                <p>Filter and download projection reports</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </CardGroup>
    </Container>
  )
}

IndexPage.Layout = Layout

export default IndexPage
